@import "~bootstrap/scss/_functions.scss";
@import "~bootstrap/scss/_variables.scss";
@import "~bootstrap/scss/_mixins.scss";

.nav-link {
  color: #35496b !important;
  &.active {
    color: black !important;
  }
}

@media (min-width: 768px) {
  .nav-link {
    font-weight: 800;
    color: rgb(255, 255, 255) !important;
    text-shadow: -1px 2px 7px black;
    &.active {
      color: black !important;
    }
  }
}

.nav-tabs {
  border-top-left-radius: 0.6rem;
  border-top-right-radius: 0.6rem;
  .nav-link {
    border-top-left-radius: 0.6rem;
    border-top-right-radius: 0.6rem;
  }
}

.navbar .collapsing .navbar-toggler {
  pointer-events: none;
}

.navbar-nav {
  .nav-link {
    // font-size: $navbar-nav-link-font-size;
    // font-family: $navbar-nav-link-font-family;
    // font-weight: $navbar-nav-link-font-weight;
    // text-transform: $navbar-nav-link-text-transform;
    // letter-spacing: $navbar-nav-link-letter-spacing;
    transition: all 0.15s linear;

    .nav-link-inner--text {
      margin-left: 0.25rem;
    }
  }
}

.navbar-brand {
  font-size: 14;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 0.875rem;
  letter-spacing: 0.05px;

  img {
    max-height: 55px;
    max-width: 100px;
    object-fit: contain;
  }
}

.navbar-light {
  .navbar-brand {
    color: gray;
  }
}

.navbar-nav {
  .nav-item {
    .media:not(:last-child) {
      margin-bottom: 1.5rem;
    }
  }
}

@include media-breakpoint-up(lg) {
  // .navbar-main {
  //     padding: 1rem 0;
  // }

  .navbar-nav {
    .nav-item {
      margin-right: 0.5rem;

      [data-toggle="dropdown"]::after {
        transition: $transition-base;
      }

      &.show {
        [data-toggle="dropdown"]::after {
          transform: rotate(180deg);
        }
      }
    }
    .nav-link {
      padding-top: 1rem;
      padding-bottom: 1rem;
      border-radius: 0.25rem;

      i {
        margin-right: 0.625rem;
      }
    }

    .nav-link-icon {
      padding-left: 0.5rem !important;
      padding-right: 0.5rem !important;
      border-radius: 0.25rem;

      i {
        margin-right: 0;
      }
    }

    // Dropdown menu
    .dropdown-menu {
      opacity: 0;
      pointer-events: none;
      margin: 0;

      &:before {
        background: $dropdown-bg;
        box-shadow: none;
        content: "";
        display: block;
        height: 16px;
        width: 16px;
        left: 20px;
        position: absolute;
        bottom: 100%;
        transform: rotate(-45deg) translateY(1rem);
        z-index: -5;
        border-radius: 0.2rem;
      }
    }

    .dropdown-menu-right {
      &:before {
        right: 20px;
        left: auto;
      }
    }

    &:not(.navbar-nav-hover) {
      .dropdown-menu {
        &.show {
          opacity: 1;
          pointer-events: auto;
          animation: show-navbar-dropdown 0.25s ease forwards;
        }

        &.close {
          display: block;
          animation: hide-navbar-dropdown 0.15s ease backwards;
        }
      }
    }

    &.navbar-nav-hover {
      .dropdown-menu {
        opacity: 0;
        display: block;
        pointer-events: none;
        transform: translate(0, 10px) perspective(200px) rotateX(-2deg);
        transition: visibility 0.25s, opacity 0.25s, transform 0.25s;
      }

      .nav-item.dropdown:hover > .dropdown-menu {
        display: block;
        opacity: 1;
        pointer-events: auto;
        visibility: visible;
        transform: translate(0, 0);
        animation: none;
      }
    }

    .dropdown-menu-inner {
      position: relative;
      padding: 1rem;
    }
  }
}

// Transparent navbar
.navbar-transparent {
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 100;
  background-color: transparent;
  border: 0;
  box-shadow: none;

  .navbar-brand {
    color: rgba(255, 255, 255, 1);
  }

  .navbar-toggler {
    color: rgba(255, 255, 255, 1);
  }

  .navbar-toggler-icon {
    background-image: $navbar-dark-toggler-icon-bg;
  }
}

@include media-breakpoint-up(lg) {
  .navbar-transparent {
    .navbar-nav {
      .nav-link {
        color: black;

        // @include hover-focus {
        //   color: $navbar-dark-hover-color;
        // }

        // &.disabled {
        //   color: $navbar-dark-disabled-color;
        // }
      }

      .show > .nav-link,
      .active > .nav-link,
      .nav-link.show,
      .nav-link.active {
        color: blue;
      }
    }

    .navbar-brand {
      color: black;

      &:hover,
      &:focus {
        color: black;
      }
    }
  }
}

// Collapse

.navbar-collapse-header {
  display: none;
}

@include media-breakpoint-down(lg) {
  .navbar-nav {
    .nav-link {
      padding: 0.625rem 0;
      color: black;
    }

    .dropdown-menu {
      box-shadow: none;
      min-width: auto;

      .media {
        svg {
          width: 30px;
        }
      }
    }
  }

  .navbar-collapse {
    width: calc(100% - 1.4rem);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1050;
    margin: 0.7rem;
    overflow-y: auto;
    height: auto !important;
    opacity: 0;

    .navbar-toggler {
      width: 30px;
      height: 30px;
      position: relative;
      cursor: pointer;
      display: inline-block;
      padding: 0;

      span {
        display: block;
        position: absolute;
        width: 100%;
        height: 2px;
        border-radius: 2px;
        opacity: 1;
        background: #283448;
      }

      :nth-child(1) {
        transform: rotate(135deg);
      }

      :nth-child(2) {
        transform: rotate(-135deg);
      }
    }

    .navbar-collapse-header {
      display: block;
      padding-bottom: 1rem;
      margin-bottom: 1rem;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    }

    .collapse-brand {
      img {
        max-height: 36px;
        max-width: 80px;
        object-fit: contain;
      }
    }

    .collapse-close {
      text-align: right;
    }
  }

  .navbar-collapse.collapsing,
  .navbar-collapse.show {
    padding: 1.5rem;
    border-radius: 0.25rem;
    background: #fff;
    box-shadow: $large-box-shadow;
    animation: show-navbar-collapse 0.2s ease forwards;
  }

  .navbar-collapse.closed {
    animation: hide-navbar-collapse 0.2s ease forwards;
    // opacity: 0;
  }

  .navbar-collapse.collapsing-out {
    animation: hide-navbar-collapse 0.2s ease forwards;
  }
}

// Keyframes

@keyframes show-navbar-collapse {
  0% {
    opacity: 0;
    transform: scale(0.95);
    transform-origin: 100% 0;
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes hide-navbar-collapse {
  from {
    opacity: 1;
    transform: scale(1);
    transform-origin: 100% 0;
  }

  to {
    opacity: 0;
    transform: scale(0.95);
  }
}

@keyframes show-navbar-dropdown {
  0% {
    opacity: 0;
    transform: translate(0, 10px) perspective(200px) rotateX(-2deg);
    transition: visibility 0.25s, opacity 0.25s, transform 0.25s;
  }

  100% {
    transform: translate(0, 0);
    opacity: 1;
  }
}

@keyframes hide-navbar-dropdown {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate(0, 10px);
  }
}

.navbar-toggler {
  border-color: transparent !important;
}

@media (min-width: 992px) {
  .navbar-expand-lg .navbar-nav {
    margin-right: unset;

    .navbar-logout {
      margin-left: auto;
    }
  }
}
