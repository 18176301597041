 

$orange: rgb(195, 113, 57);
$pink: rgb(240, 196, 217);
$yellow: rgb(242, 195, 69);
$cream: rgb(250, 227, 195);
$light-cream: rgb(255, 247, 238);
$navy: rgb(53, 73, 107);
$green: rgb(183, 216, 178);
$pale-green: rgb(239, 246, 238);
$dark-gray: rgb(87, 97, 126);

$primary-color: $green;
$text-color-light: $cream;
$accent-color: $orange;
$tertiary-color: $yellow;
$complementary-color: $pink;
$text-color-dark: $navy;
$black: #0f0f0f;
$white: #fff;

$theme-colors: (
  "primary": $pale-green,
  "secondary": $orange,
);

$large-box-shadow: 0 31px 41px 0 rgba(32, 42, 53, 0.2),
  0 2px 16px 0 rgba(32, 42, 54, 0.08);

@import "~bootstrap/scss/bootstrap";
@import "navbar";

// AOS styles
$aos-distance: 10px;
@import "node_modules/aos/src/sass/aos.scss";

#root {
  height: 100vh;
 }

body {
  font-family: 'Inter', sans-serif;

  background-color: rgb(181,193,204);
  color: black;
}

.title-font {
  color: rgb(36, 32, 32);
  font-family: 'Outfit';
}

#registration-frame-container {
  background: transparent;
}

#root {
  background: url("./images/paris.jpg") 0px 0px no-repeat;
  background-size: cover;
}

#registration-container {
  height: 100vh;
  width: 100vw;
}
#registration-message {
  width: 100vw;

  text-align: center;
  a {
    color: $orange !important;
    text-decoration: none;
  }
}

#registration-frame-frame-container {
  width: 100vw;
  margin: auto;
}
#registration-frame-container {
  width: 100vw;
}

.full-height-section {
  min-height: max(80vh, 720px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-left: 20px;
  padding-right: 20px;
}

#register {
  min-height: max(80vh, 400px);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
}

.btn-cta {
  font-size: 1.2rem;
  color: white;
  font-weight: 700;
  background-color: #c61010 !important;
  padding: 10px 1em;
  // text-shadow: $orange -1px 1px 0px, $orange 1px 1px 0px, $orange 1px -1px 0px,
  //   $orange -1px -1px 0px, $navy 1.5px 2px 0px, $yellow 0px 3px 0px,
  //   $pink 0px 5px 0px, $green 0px 7px 0px;
}

#about {
  max-width: 90vh;
  text-align: left;
  color: black;
  background: $green;
  border-radius: 40px;
}
#relax {
  max-width: 90vh;
  text-align: left;
  background: $light-cream;
  border-radius: 40px;
  padding: 20px;
}
.aos-init {
  img {
    max-width: 300px;
  }
}

.font-plain {
  font-family: "NotoSerif", apple-system, BlinkMacSystemFont, "Segoe UI",
    Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
}

h2 {
  font-weight: 700;
  color: $navy;
}
